@import '../abstracts/mixins';

.review {
  display: flex;
  align-items: stretch;
  .no-margin {
    margin: 0 !important;
  }
  .table {
    padding-right: 15px;
  }
  .review-summary-container {
    padding: 0;
  }
  .review-summary-wrapper {
    position: relative;
    height: 100%;
  }
  .overlay {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 99999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .review-pop-up {
      width: 300px;
      height: 200px;
      padding: 30px;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.15);
      box-sizing: border-box;
      border-radius: 10px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h4 {
        margin-bottom: 20px;
        text-align: center;
      }
    }
  }
  .review-summary {
    padding: 25px;

    &__row {
      padding: 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    }

    &__buttons-row {
      margin-top: 25px;
    }

    .round-icon {
      display: flex;
      flex-direction: row;
      align-items: center;

      .MuiSvgIcon-root {
        width: 25px;
        height: 25px;
        padding: 0;
        margin-right: 25px;
        color: black;
      }
    }
  }

  .approve-btn {
    background: $green !important;
    height: 38px;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 20px;
    &__label {
      p {
        padding-left: 10px;
      }
    }
    &__no-label {
      p {
        padding-left: 10px;
        width: 100%;
      }
    }
  }

  .reject-button {
    background: $red !important;
    height: 38px;
    padding-left: 20px;
    padding-right: 20px;
    &__label {
      p {
        padding-left: 10px;
      }
    }
  }
  .needs-review {
    background: #fcba58 !important;
    height: 30px;
    padding-left: 20px;
    padding-right: 20px;
    &__label {
      p {
        font-size: 12px !important;
      }
    }
  }

  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #1d1d1d;
  }

  .review-changes {
    .content {
      margin-bottom: 20px;
      padding: 20px;

      &__text {
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 25px;
      }
      &__bold {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 25px;
      }
    }
    .before {
      background: #fff2d9;
      border-radius: 5px;
    }
    .after {
      background: #d9e6ff;
      border-radius: 5px;
    }

    &__video {
      width: 80%;
      max-width: 200px;
      height: 100px;
      background-color: $black;
    }
  }

  .review-changes-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
}
