.admin-home {
  flex: 1 1 100%;
  align-self: stretch;

  display: flex;
  flex-flow: row;
  justify-content: stretch;
  align-items: stretch;

  background-color: $secondary-background;

  .menu {
    background-color: $menu-background;
    color: $menu-text;

    flex: 0 0 auto;

    // left gutter + icon width + right gutter
    width: calc(2px + 16px + 24px + 16px + 2px);

    & > .MuiDrawer-paper {
      background-color: $menu-background;
      color: $menu-text;
    }

    .menu-btn {
      display: inline-flex;
      flex-flow: row;

      border-radius: 0;
      padding: 5px 16px;
      min-width: auto;

      background-color: $menu-background;
      color: $menu-text;

      &.selected {
        background-color: $btn-background;
        color: $btn-text;
      }

      &:hover {
        background-color: $grey-light;
        color: $primary-text;
      }

      .label {
        display: none;
      }
    }

    &.drawer-open {
      // left gutter + icon width + separation + text width + right gutter
      width: calc(2px + 16px + 24px + 30px + 100px + 16px + 2px);

      .menu-btn .label {
        display: block;

        margin-left: 30px;
        width: 100px;
        text-align: left;
      }
    }
  }

  .content {
    flex: 1 1 100%;
    background-color: $overlay-background;
    color: $secondary-text;

    overflow: auto;

    &.drawer-open {
      width: calc(100% - 128px);
    }

    .admin-root {
      padding: 15px;
    }
  }
}
