.project-management {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  .text-field {
    width: 100%;
    margin-bottom: 15px;
  }

  .no-password {
    font-style: italic;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: $black;
    margin-left: 15px;
  }

  .services-header {
    margin-top: 50px;
  }

  .dropdown {
    width: 100%;
    margin-bottom: 15px;
  }
}
