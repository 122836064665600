@import '../../abstracts/mixins';
.page-header {
  &__title {
    padding-right: 30px;
  }
  .MuiIconButton-root {
    color: black;
  }
  h3 {
    /* Title */
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    display: flex;
    align-items: center;
    color: $primary-text;
    @include tablet-ipad {
      font-size: 33px;
      line-height: 43px;
    }
  }
  h4 {
    /* Subheading */
    margin-top: 10px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: rgba(29, 29, 29, 0.75);
  }
  .capitalize {
    text-transform: capitalize;
  }
  .round-icon {
    width: 50px;
    height: 50px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    background: white;
    padding: 0;
    margin-right: 25px;
    color: black;

    @include tablet-ipad {
      margin-top: 20px;
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  .no-margin {
    margin: 0;

    .MuiSvgIcon-root {
      font-size: 30px;
    }
  }
  .MuiIconButton-root:hover {
    background-color: white !important;
  }

  .help-desk-button-wrapper {
    cursor: pointer;
  }
  .help-desk-button {
    background: #ebecf2;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 20px 10px 45px;
    border-radius: 10px;
    max-height: 50px;
    margin-left: -30px;
    p {
      color: $primary-text;
      font-size: 15px;
    }
  }

  &__logout-btn {
    padding-left: 20px;
  }
}
