@import '../abstracts/mixins';

.set-password-container {
  padding: 0 200px 0 25px;

  &__title {
    padding-bottom: 50px;
  }

  &__input {
    width: 100%;
    max-width: 350px;
  }

  &__button {
    width: 100%;
    max-width: 250px;
  }
}
