@import '../../abstracts/mixins';

.dashboard-container {
  display: flex;
}

.dashboard-container__unauthorized {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin-left: 300px;
  background-image: url('/assets/images/SpacesLogo.png');
  background-attachment: fixed;
  background-position: right;
  background-repeat: no-repeat;
  background-size: auto calc(100vh - 80px);
  height: 100vh;

  @include ipad-pro-portrait {
    background-image: unset;
  }
  @include tablet-ipad {
    background-image: unset;
  }
}

.dashboard-body-container {
  display: flex;
  flex-grow: 1;
  margin-left: 300px;
  margin-bottom: 10vh;

  @include tablet-ipad {
    margin-bottom: 20vh;
  }

  .chart-card {
    border-radius: 10px;
    border: 1px solid #dee6eb;
    background-color: #ffffff;
    width: 100%;
    padding: 30px;
  }
}

.dashboard-body {
  border-radius: 10px;
  border: 1px solid $border-grey;
  min-height: 500px;
  background-color: $white;
  padding: 25px;

  .form-container {
    max-width: 100%;
    width: 100%;
  }

  .service-title {
    margin: 10px 0px;
    padding-right: 8px;
  }
}

.header {
  margin: 100px 0px 25px;
}

// Max-width of container, we can increase if we need
.MuiContainer-root {
  max-width: 1500px !important;
}
