.unauthorized-page {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  height: 100%;
  min-height: 100vh;

  background-image: url('/assets/images/SpacesLogo.png');
  background-attachment: fixed;
  background-position: right;
  background-repeat: no-repeat;
  background-size: auto calc(100vh - 80px);

  .sidebar {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-content: center;

    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;

    background-color: $primary-background;
    padding: 65px;

    img {
      object-fit: contain;

      width: 171px;
      height: 566px;

      @media only screen and (max-height: calc(566px + 65px + 65px)) {
        width: 128px;
        height: 424px;
      }

      @media only screen and (max-height: calc(424px + 65px + 65px)) {
        width: 86px;
        height: 283px;
      }

      @media only screen and (max-height: calc(283px + 65px + 65px)) {
        width: 43px;
        height: 141px;
      }
    }
  }

  .bg-logo {
    position: absolute;
  }
}
