@import '../abstracts/mixins';

.login-container {
  padding: 0px 10%;

  &__title {
    padding-bottom: 50px;
    @include ipad-pro-portrait-and-landscape {
      max-width: 68%;
    }
    @include tablet-ipad {
      max-width: 68%;
    }
  }

  &__input {
    width: 350px;
    margin: 10px 0px !important;
  }

  &__button {
    width: 250px;
    margin-top: 30px;
  }

  &__forgot-password {
    padding: 20px 0px;
    display: flex;
    a {
      text-decoration: none;
      color: unset;
    }
  }
}
