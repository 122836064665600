.section-frame {
  header {
    a {
      color: $secondary-text;

      &:hover {
        color: $primary-text;
      }
    }
  }
}
