.projects {
  &__card {
    width: 150px; //
    min-height: 185px;//
    background: #ffffff;//
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);//
    border-radius: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .delete-header {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    .project-logo {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      max-height: 70px;
      margin-bottom: 15px;
      margin-left: auto;
      margin-right: auto;
      img {
        height: 70px;
        object-fit: contain;
        max-width: 95%;
      }
    }
    .view-project-button {
      width: 100%;
      height: 100%;
      padding-top: 0;
      .MuiButton-label {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        //padding-right: 10px;
        height: 100%;
        p {
          font-size: 15px !important;
          line-height: 18px !important;
          text-transform: capitalize;
          color: rgba(29, 29, 29, 0.75);
          font-weight: normal;
          padding-left: 5px;
        }
        h3 {
          text-align: left;
          font-size: 13px;
          padding-left: 5px;
        }
      }
    }
  }
  &__add-card {
    width: 150px;
    height: 185px;
    background: #e4e4e4;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    .add-button {
      height: 100%;
      width: 100%;
    }
  }
}
