.row {
  display: flex;
  flex-direction: row;
}

.hidden {
  height: 1px;
  width: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  overflow: hidden;
}

.float-right {
  float: right;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
