.summary-buttons {
  border-radius: 10px;
  border: 1px solid #dee6eb;
  background-color: #ffffff;
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  h1 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    color: black;
  }

  p {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #9e9e9e;
  }

  &__child {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid #dee6eb !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    &:last-child {
      border: none !important;
    }
  }
}
