.create-card {
  display: flex;
  flex-flow: column;
  justify-content: stretch;

  .title {
    display: flex;
    flex-flow: row;
    align-items: center;
  }

  .action {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;

    flex: 1 1 100%;
  }
}
