//$fontMappings: (
//  "Thin": 100,
//  "ExtraLight": 200,
//  "Light": 300,
//  "Regular": 400,
//  "Medium": 500,
//  "SemiBold": 600,
//  "Bold": 700,
//  "ExtraBold": 800,
//  "Black": 900
//);
//
//@each $file, $weight in $fontMappings {
//  @font-face {
//    font-family: "Montserrat";
//    font-style: normal;
//    font-weight: $weight;
//    src: local("MontSerrat-" + $file), url("/fonts/MontSerrat-"+$file+".ttf"),
//      local("Montserrat");
//  }
//  @font-face {
//    font-family: "Montserrat";
//    font-style: italic;
//    font-weight: $weight;
//    src: local("MontSerrat-" + $file + "Italic"),
//      url("/fonts/MontSerrat-"+$file+"Italic.ttf"), local("Montserrat");
//  }
//}

html,
body {
  font-family: 'Inter', sans-serif;
  color: $primary-text;
}

h1,
h2,
h3,
h4,
h5,
h6 {
}

h1 {
  font-size: 64px;
  font-weight: bold;
}

h2 {
  font-size: 20px;
  font-weight: normal;

  strong {
    font-weight: 900;
  }
}

p {
  font-size: 16px;
  line-height: 32px;
}
