.user-management {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  position: relative;

  .text-field {
    width: 100%;
    margin-bottom: 15px;
  }

  .no-password {
    font-style: italic;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: $black;
    margin-left: 15px;
  }

  &__details {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-height: 500px;
  }

  &__submit {
    position: absolute;
    bottom: 25px;
  }
}
