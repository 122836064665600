.graph-card {
  flex: 0 0 25%;

  display: flex;
  flex-flow: column;

  padding: 30px;

  .legend {
    display: flex;
    flex-flow: row;

    justify-content: space-evenly;

    .item {
      display: flex;
      flex-flow: row;
      align-items: center;

      .dot {
        display: inline-flex;

        border-radius: 1.5px;
        width: 10px;
        height: 3px;
      }
    }
  }
}
