.services {
  width: 100%;

  .checkbox-item {
    margin-top: 5px;
  }
  .add-title {
    align-items: center;
    p {
      margin-left: 10px;
    }
  }
}
