.service-container {
  padding: 10px 0px;
}

.service-card {
  width: 150px;
  height: 150px;
  color: $white;
  cursor: pointer;
  margin-right: 10px !important;
  margin-bottom: 10px !important;
}

.services-list {
  display: flex;
  flex-wrap: wrap;
}
