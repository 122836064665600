*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  box-sizing: border-box;
  background-color: $body-background;
  color: $primary-text;
  max-height: 100%;
  max-width: 100vw;
}

#app {
  width: 100%;
  height: 100%;
  max-width: 100vw;
  min-height: 100vh;
}

// text fields rewrite CSS
.MuiOutlinedInput-root {
  &.Mui-focused fieldset {
    border-color: black !important;
  }
}
.MuiFormLabel-root {
  color: black !important;
}

.dialog-actions {
  padding-bottom: 16px !important;
}
