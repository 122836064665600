.analytics {
  .table {
    background-color: $table-background;
    color: $table-text;

    border-radius: 8px;

    .MuiTableCell-body,
    .MuiTableCell-head {
      border-bottom-color: $grey-medium;
      min-width: 10vw;
    }

    .MuiTableCell-footer {
      border: 0;
    }

    .data-cell {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      max-width: 50vw;
    }

    .analytics-download {
      display: flex;
      flex-flow: row;
      align-items: baseline;

      > * {
        margin-right: 10px;
      }
    }

    .header,
    .footer {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      align-items: baseline;
    }

    .filter-button {
      &.active {
        color: $primary-text;
      }

      &.inactive {
        color: rgba($primary-text, 0.2);
      }
    }
  }
}

// Popper sits outside of the .analytics markup
.filter-container {
  padding: 5px;
}
