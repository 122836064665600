.dashboard {
  .admin-root {
    display: flex;
    flex-flow: row wrap;

    align-items: stretch;
    align-content: flex-start;
    justify-content: flex-start;
  }

  p {
    flex: 1 0 100%;
  }
}
