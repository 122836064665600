.projects-roles {
  width: 100%;
  .add-project-title {
    margin-top: 15px;
    align-items: center;
    p {
      margin-left: 10px;
    }
  }
  .project-select-wrapper {
    width: 80%;
    margin-top: 15px;
  }

  .project-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    p {
      margin-right: 15px;
      font-weight: bold;
      font-size: 16px;
    }
  }
  .project-title-view-only {
    background: #ffffff;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 15px;
    margin-bottom: 20px;
    max-width: 140px;
  }
}
