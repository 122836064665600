.menu-wrapper {
  padding: 10px;
  width: 100%;
}

.menu-item-wrapper {
  border: none !important;
  outline: 0 !important;
}

.project-summary {
  line-height: 12px;
  margin-right: 20px !important;
  //padding: 10px;
  //height: 100px;

  &__card {
    background-color: $white !important;
    color: unset !important;
    padding: 5px 15px;
    cursor: pointer;
    min-width: 150px;
    // margin-right: 20px;
    border: none !important;

    &--active {
      background-color: $red !important;
      color: $white !important;
      padding: 5px 15px;
    }
  }

  &__card-title {
    @include font-size(12px);
    line-height: 25px;
  }

  &__card-name {
    @include font-size(16px);
  }
}
