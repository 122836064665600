$white: #ffffff;
$black: #000000;
$red: #ea4969; // Spaces red
$green: #3ee1aa;
$green-light: #d8f9ee;
$green-dark: #3cba94;
$grey-dark: #1d1d1d;
$grey-medium: #333333;
$grey-light: #f7f8fc;
$border-grey: #dee6eb;

$primary-background: $white;
$secondary-background: $white;
$body-background: $grey-light;

$primary-text: #1d1d1d;
$secondary-text: $white;
$tertiary-text: $red;

$btn-background: $red;
$btn-text: $white;
$btn-alt-background: $grey-dark;
$btn-alt-text: $white;
$btn-subtle-background: rgba(0, 0, 0, 0);
$btn-subtle-text: $grey-dark;

$title-background: $red;
$title-text: $secondary-text;

$menu-background: $grey-medium;
$menu-text: $white;

$table-background: $grey-light;
$table-text: $black;

$overlay-background: rgba($grey-medium, 0.2);
