.documents {
  .admin-root {
    display: flex;
    flex-flow: row wrap;

    align-items: stretch;
    align-content: flex-start;
    justify-content: flex-start;

    .selection-card {
      flex: 0 1 calc(25% - 30px);
      min-width: 250px;
      max-width: 400px;

      display: flex;
      flex-flow: column;
      justify-content: stretch;
      align-items: stretch;

      margin: 15px;

      background-color: $table-background;
      color: $table-text;
      box-sizing: border-box;

      .value-field {
        padding-left: 10px;
      }

      .body {
        flex: 1 1 auto;
        display: flex;
        flex-flow: column;
        justify-content: stretch;
        align-items: stretch;
      }

      .footer {
        justify-content: flex-end;
      }

      &:hover {
        //background-color: color.scale(
        //  $table-background,
        //  $lightness: -15%
        //);
        border: 1px white solid;
      }

      .edit-line {
        font-size: 0.7em;
      }
    }
  }
}
.document-editor {
  .admin-root {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: flex-start;

    .editor-body {
      padding: 10px;
      background-color: $table-background;
      color: $table-text;

      flex: 0 0 60vw;
      max-width: 1000px;

      form {
        display: flex;
        flex-flow: column;

        & > * {
          margin: 5px;
        }
      }
    }

    .info-body {
      margin-left: 20px;
      padding: 10px;
      background-color: $table-background;
      color: $table-text;

      td {
        padding: 3px;

        &:first-child {
          text-align: right;
        }
      }
    }
  }
}
